import { TextFieldProps } from '@mui/material/TextField';
import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

/**
 * Props for the DateTimePickerField component.
 */
export interface DateTimePickerFieldProps<
  TFormValues extends FieldValues = FieldValues,
> extends Omit<DateTimePickerProps<Date>, 'onChange' | 'defaultValue'>,
    Pick<TextFieldProps, 'required' | 'helperText' | 'fullWidth'> {
  name: Path<TFormValues>; // The name of the field in the form.
  minDateTime?: Date; // The minimum selectable date and time.
  maxDateTime?: Date; // The maximum selectable date and time.
  disablePast?: boolean; // If true, past dates and times are disabled.
  disableFuture?: boolean; // If true, future dates and times are disabled.
  control?: Control<TFormValues>; // The `control` object from `react-hook-form`.
  onChange?: (value: Date | null) => void; // A function to call when the value changes.
}

/**
 * A date and time picker component that integrates with `react-hook-form`.
 */
export function DateTimePickerField<
  TFormValues extends FieldValues = FieldValues,
>({
  name,
  label,
  minDateTime,
  maxDateTime,
  disablePast,
  disableFuture,
  control,
  required,
  helperText,
  fullWidth,
  onChange: parentOnChange,
  ...props
}: DateTimePickerFieldProps<TFormValues>) {
  const {
    field: { onBlur, onChange, value, ref },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div data-chromatic="ignore">
      <DateTimePicker
        views={['year', 'month', 'day', 'hours', 'minutes']}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        disablePast={disablePast}
        disableFuture={disableFuture}
        label={label}
        onChange={(e) => {
          onChange(e);
          parentOnChange?.(e);
        }}
        value={value}
        {...props}
        slotProps={{
          textField: {
            ...props?.slotProps?.textField,
            ref: ref,
            error: !!error,
            helperText: error?.message || helperText,
            required: required,
            fullWidth: fullWidth,
            onBlur: onBlur,
          },
        }}
      />
    </div>
  );
}

export default DateTimePickerField;
